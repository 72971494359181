import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const solidGateSubscriptionCreate = createAsyncThunk(
  "solidGateSubscriptionCreate",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/solidgate-subscription-create`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const solidGateSubscriptionCreateSlice = createSlice({
  name: "solidGateSubscriptionCreate",
  initialState: {
    isLoading: false,
    solidGateSubscriptionCreate: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [solidGateSubscriptionCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [solidGateSubscriptionCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.solidGateSubscriptionCreate = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [solidGateSubscriptionCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default solidGateSubscriptionCreateSlice.reducer;
