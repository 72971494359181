import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const getPhoneTrackingCurrency = createAsyncThunk("getPhoneTrackingCurrency", async () => {
  try {
    const response = await AxiosInstance.get(`/pricing/list/phone_tracking`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const getPhoneTrackingCurrencySlice = createSlice({
  name: "getPhoneTrackingCurrency",
  initialState: {
    isLoading: false,
    getPhoneTrackingCurrencyData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getPhoneTrackingCurrency.pending]: (state) => {
      state.isLoading = true;
    },
    [getPhoneTrackingCurrency.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.getPhoneTrackingCurrencyData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [getPhoneTrackingCurrency.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getPhoneTrackingCurrencySlice.reducer;
