import React from "react";
import HomePageComponent from "./homePage";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const HomePage = (props) => {
  const { productList, contant, from, productListSingle } = props;
  const location = useLocation();
  const signalTrail = location.pathname.includes("trace") ? productList : productListSingle
  return (
    <HomePageComponent
      productList={productList}
      contant={contant}
      from={from}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData,
    productListSingle: state.getProductListSingle.productListSingleData,
  };
};

export default connect(mapStateToProps, null)(HomePage);
