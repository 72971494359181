import React from "react";
import applePayButton from "../../assets/checkout/applepayButton.png";
import axios from "axios";
import { connect } from "react-redux";
import { API_KEY } from "../../environment";

const ApplePayPage = (props) => {
  const { price, currency, handleSubmit, setIsLoading, country } = props;

  async function onApplePayButtonClicked() {
    var ApplePaySession = new window.ApplePaySession(6, {
      countryCode: country.toUpperCase(),
      currencyCode: currency.toUpperCase().substr(0, 3),
      supportedNetworks: ["amex", "discover", "masterCard", "visa"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: "Tracelo.com",
        amount: price,
      },
    });
    try {
      setIsLoading(true);
      ApplePaySession.begin();
      ApplePaySession.onvalidatemerchant = async (event) => {
        var validationURL = event.validationURL;
        const merchantSession = await validateSession(validationURL);
        ApplePaySession.completeMerchantValidation(merchantSession);
      };
      ApplePaySession.onpaymentauthorized = async (event) => {
        var token = event.payment.token.paymentData;
        handleSubmit("apple_pay", token);
        token &&
          ApplePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
      };
      ApplePaySession.oncancel = async (event) => {
        setIsLoading(false);
      };
    } catch (error) {
      ApplePaySession.oncancel = async (event) => {
        setIsLoading(false);
      };
      console.error("error:", error);
      setIsLoading(false);
    }
  }
  const validateSession = async (url) => {
    const response = await axios.post(
      `${API_KEY}/setting/apple-pay-validation`,
      {
        url: url,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return response.data.data;
  };
  return (
    <div onClick={() => onApplePayButtonClicked()}>
      <img src={applePayButton} alt="" height={40} />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    ipData: state.getIPIfy.getIPIfyData,
  };
};

export default connect(mapStateToProps, null)(ApplePayPage);
