import axios from "axios";
import {logAPI} from "../../utils/commonUtils";
import {applePay_merchantId} from "../../environment";

export const axiosAPI = async (apiPayload) => {
  try {
    const headers = {"Content-Type": "application/json"};
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      headers["x-site"] = "http://tracelo.com";
    }
    const response = await axios.post(
      "https://api.tracelo.com/api/subscription/v3/subscription-api-debug",
      apiPayload, // body data
      {headers} // headers
    );
    if (response.data) {
      logAPI(
        "fe/subscription-debug-api",
        "subscription debug api response from FE",
        response.data,
        200
      );
    } else {
      logAPI(
        "fe/subscription-debug-api",
        "subscription debug error response from FE",
        null,
        400
      );
    }
  } catch (error) {
    let message = `${error?.message} -  ${error.stack}`;
    logAPI("fe/subscription-debug-api-error", message, apiPayload, 400);
  }
  return true;
};

export const checkGooglePayAvailability = async () => {
  try {
    const baseRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      existingPaymentMethodRequired: true
    };
    const allowedCardNetworks = [
      "AMEX",
      "DISCOVER",
      "INTERAC",
      "JCB",
      "MASTERCARD",
      "VISA"
    ];
    const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
    const baseCardPaymentMethod = {
      type: "CARD",
      parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks
      }
    };

    const paymentsClient = new window.google.payments.api.PaymentsClient({
      environment: "PRODUCTION"
    });

    const isReadyToPayRequest = {
      ...baseRequest,
      allowedPaymentMethods: [baseCardPaymentMethod]
    };

    const response = await paymentsClient.isReadyToPay(isReadyToPayRequest);
    return response?.result && response?.paymentMethodPresent;
  } catch (error) {
    console.error("Error checking Google Pay availability:", error);
    return false;
  }
};

export const checkApplePayAvailability = () => {
  return new Promise((resolve) => {
    if (window.ApplePaySession) {
      var merchantIdentifier = applePay_merchantId;
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          merchantIdentifier
        );
      promise
        .then((canMakePayments) => {
          resolve(canMakePayments);
        })
        .catch((error) => {
          console.error("Error checking Apple Pay availability:", error);
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
};

