import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
export const solidGateProductList = createAsyncThunk(
  "solidGateProductList",
  async () => {
    try {
      const response = await AxiosInstance.get(
        `/pricing/product-list/solidgate`
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const solidGateProductListSlice = createSlice({
  name: "solidGateProductList",
  initialState: {
    isLoading: false,
    solidGateProductList: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [solidGateProductList.pending]: (state) => {
      state.isLoading = true;
    },
    [solidGateProductList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.solidGateProductList = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [solidGateProductList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default solidGateProductListSlice.reducer;
