import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const subscriptionCreateV7 = createAsyncThunk(
  "subscriptionCreateV7",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/v7/create`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionCreateV7Slice = createSlice({
  name: "subscriptionCreateV7",
  initialState: {
    isLoading: false,
    subscriptionCreateV7Data: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [subscriptionCreateV7.pending]: (state) => {
      state.isLoading = true;
    },
    [subscriptionCreateV7.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionCreateV7Data = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [subscriptionCreateV7.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default subscriptionCreateV7Slice.reducer;
