import React from "react";
import CurrencyComponent from "./currency";
import { connect } from "react-redux";
import { getCurrency } from "../../redux/slice/getCurrencySlice";
import { useEffect } from "react";
import { addEditCurrency } from "../../redux/slice/addEditCurrencySlice";
import { deleteCurrency } from "../../redux/slice/deleteCurrencySlice";
import { getTrailCurrency } from "../../redux/slice/getTrailCurrencySlice";
import { getSpreedlyCurrency } from "../../redux/slice/getSpreedlyCurrencySlice";
import { getPhoneTrackingCurrency } from "../../redux/slice/getPhoneTrackingCurrencySlice";
import { upsertStripe } from "../../redux/slice/upsertStripeSlice";
import { upsertSpreedly } from "../../redux/slice/upsertSpreedlySlice";

const Currency = (props) => {
  const {
    getCurrency,
    getCurrencyData,
    addEditCurrency,
    deleteCurrency,
    getTrailCurrency,
    getSpreedlyCurrency,
    getPhoneTrackingCurrency,
    getTrailCurrencyData,
    getSpreedlyCurrencyData,
    getPhoneTrackingCurrencyData,
    callUpsertStripe,
    callUpsertSpreedly,
  } = props;
  useEffect(() => {
    getCurrency();
    getTrailCurrency();
    getSpreedlyCurrency();
    getPhoneTrackingCurrency();
    // eslint-disable-next-line
  }, []);

  return (
    <CurrencyComponent
      getCurrencyData={getCurrencyData.getCurrencyData}
      addEditCurrency={addEditCurrency}
      getCurrency={getCurrency}
      deleteCurrency={deleteCurrency}
      getTrailCurrencyData={getTrailCurrencyData.getTrailCurrencyData}
      getSpreedlyCurrencyData={getSpreedlyCurrencyData.getSpreedlyCurrencyData}
      getPhoneTrackingCurrencyData={
        getPhoneTrackingCurrencyData.getPhoneTrackingCurrencyData
      }
      callUpsertStripe={callUpsertStripe}
      callUpsertSpreedly={callUpsertSpreedly}
      getTrailCurrency={getTrailCurrency}
      getSpreedlyCurrency={getSpreedlyCurrency}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    getCurrencyData: state.getCurrencyData,
    getTrailCurrencyData: state.getTrailCurrencyData,
    getSpreedlyCurrencyData: state.getSpreedlyCurrencyData,
    getPhoneTrackingCurrencyData: state.getPhoneTrackingCurrencyData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCurrency: () => dispatch(getCurrency()),
    getTrailCurrency: () => dispatch(getTrailCurrency()),
    getSpreedlyCurrency: () => dispatch(getSpreedlyCurrency()),
    getPhoneTrackingCurrency: () => dispatch(getPhoneTrackingCurrency()),
    addEditCurrency: (data) => dispatch(addEditCurrency(data)),
    deleteCurrency: (data) => dispatch(deleteCurrency(data)),
    callUpsertStripe: (data) => dispatch(upsertStripe(data)),
    callUpsertSpreedly: (data) => dispatch(upsertSpreedly(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Currency);
