/* global gtag */
import React, {useState} from "react";
import {connect} from "react-redux";
import {trailSubscription} from "../../redux/slice/trailSubscriptionSlice";
import {useEffect} from "react";
import {standardSubscription} from "../../redux/slice/standardSubscriptionSlice";
import {settingBanIp} from "../../redux/slice/settingBanIpSlice";
import {Cookies} from "react-cookie";
import {savegclid} from "../../redux/slice/savegclidSlice";
import {getGclidDetails} from "../../redux/slice/getGclidDetailsSlice";
import {gclidCheck} from "../../redux/slice/gclidCheckSlice";
import {googleOauth2} from "../../redux/slice/googleOauth2Slice";
import {locatePhoneNumberIPQS} from "../../redux/slice/locatePhoneNumberIPQSlice";
import {useSearchParams} from "react-router-dom";
import {createSubscriptionDebug} from "../../redux/slice/createSubscriptionDebugSlice";
import {getSendDetails} from "../../redux/slice/getSendDetailsSlice";
import SignUpSolidGateComponent from "./signUpSolidGate";

const SignUpSolidGate = (props) => {
  const {
    callTrailSubscription,
    solidGateProductList,
    createSubscription,
    trailSubscription,
    callStandardSubscription,
    standardSubscription,
    subscription,
    page,
    callSettingBanIp,
    callgclidData,
    getGclidDetails,
    callGclidCheck,
    ipData,
    callGoogleOauth2,
    locatePhoneNumberIPQS,
    callLocatePhoneNumberIPQS,
    callSubscriptionDebug,
    sendDetailsData,
    getSendDetails
  } = props;
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const pageVersion = page === "track" || page === "trace" ? "b" : "w";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cur = cookie.get("currency");
  const [ip, setIp] = useState();
  const [gclid, setgclid] = useState();
  const [data, setData] = useState({
    email: "",
    currency: "",
    language: ""
  });
  useEffect(() => {
    gtag("event", "page_view", {
      page_version: pageVersion,
      send_to: "G-K38QXZZP92"
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setData((prev) => ({
      ...prev,
      currency: cur ? cur : "usd",
      language: lang ? lang : "en"
    }));
    // eslint-disable-next-line
  }, [lang, cur]);
  // Stripe Payment Flow
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIp(data.ip))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    if (ip) {
      getGclidDetailsData();
    }
    // eslint-disable-next-line
  }, [ip]);
  const getGclidDetailsData = async () => {
    const res = await getGclidDetails(ip);
    if (res.type === "getGclidDetails/fulfilled") {
      setgclid(res.payload.data);
    }
  };
  useEffect(() => {
    const {D, n} = searchNumber;
    const cleanNumber = (number) => number.replace(/\D/g, "");

    if (D && n) {
      const cleanD = cleanNumber(D);
      const cleanN = cleanNumber(n);

      if (cleanD && cleanN) {
        try {
          const data = {
            phone_number_prefix: cleanD,
            phone_number: cleanN
          };
          callLocatePhoneNumberIPQS(data);
        } catch (error) {
          console.error("Invalid phone number:", error);
        }
      }
    }
  }, [searchNumber.D, searchNumber.n]);
  return (
    <SignUpSolidGateComponent
      setData={setData}
      solidGateProductList={solidGateProductList}
      data={data}
      callTrailSubscription={callTrailSubscription}
      callStandardSubscription={callStandardSubscription}
      standardSubscription={standardSubscription}
      subscriptionData={createSubscription}
      trailSubscription={trailSubscription}
      subscription={subscription}
      page={page}
      callSettingBanIp={callSettingBanIp}
      callgclidData={callgclidData}
      gclid={gclid}
      callGclidCheck={callGclidCheck}
      ipData={ipData}
      googleOauth2={callGoogleOauth2}
      locatePhoneNumberIPQS={locatePhoneNumberIPQS}
      callSubscriptionDebug={callSubscriptionDebug}
      sendDetailsData={sendDetailsData}
      getSendDetails={getSendDetails}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    solidGateProductList: state.solidGateProductList?.solidGateProductList,
    createSubscription: state.createSubscription,
    trailSubscription: state.trailSubscription,
    standardSubscription: state.standardSubscription,
    ipData: state.getIPIfy.getIPIfyData,
    locatePhoneNumberIPQS: state?.locatePhoneNumberIPQS?.result?.data?.data,
    sendDetailsData: state.sendDetailsData.getSendDetailsData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callTrailSubscription: (data) => dispatch(trailSubscription(data)),
    callStandardSubscription: (data) => dispatch(standardSubscription(data)),
    callSettingBanIp: (data) => dispatch(settingBanIp(data)),
    callgclidData: (data) => dispatch(savegclid(data)),
    getGclidDetails: (id) => dispatch(getGclidDetails(id)),
    callGclidCheck: (data) => dispatch(gclidCheck(data)),
    callGoogleOauth2: (data) => dispatch(googleOauth2(data)),
    callLocatePhoneNumberIPQS: (data) => dispatch(locatePhoneNumberIPQS(data)),
    callSubscriptionDebug: (data) => dispatch(createSubscriptionDebug(data)),
    getSendDetails: (data) => dispatch(getSendDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpSolidGate);
