import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CiWarning } from "react-icons/ci";
import { TbEdit } from "react-icons/tb";
import { Button, Input, Modal, Select, Space, Table, Tabs } from "antd";
import "./currency.scss";
import { currency_symbol, renderRTLClass } from "../../utils/commonUtils";
import Notify from "../common/Notify/notify";
import { SearchOutlined } from "@ant-design/icons";
import DashboardHeader from "../dashboard/DashboardHeader";
import { useSelector } from "react-redux";

const CurrencyComponent = (props) => {
  const {
    addEditCurrency,
    getCurrency,
    deleteCurrency,
    getTrailCurrencyData,
    getSpreedlyCurrencyData,
    getPhoneTrackingCurrencyData,
    callUpsertStripe,
    callUpsertSpreedly,
    getTrailCurrency,
    getSpreedlyCurrency,
  } = props;

  const { t } = useTranslation();
  const superAdmin =
    useSelector(
      (state) => state?.authenticate?.authenticateData?.data?.last_name
    ) === "super_admin";
  const [openDeleteCurrencyModal, setOpenDeleteCurrencyModal] = useState(false);
  const [openEditCurrencyModal, setOpenEditCurrencyModal] = useState(false);
  const [editCurrencyValues, setEditCurrencyValues] = useState({});
  const [deleteCurrencyValues, setDeleteCurrencyValues] = useState({});
  const [validateErrors, setEditModalError] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("stripeTrial");
  const [tableData, setTableData] = useState([]);
  const [editDataCollation, setEditDataCollation] =
    useState(editCurrencyValues);
  const [getCurrencyData, setGetCurrencyData] = useState({});

  useEffect(() => {
    const noScroll = openDeleteCurrencyModal || openEditCurrencyModal;
    document.body.classList.toggle("no-scroll", noScroll);
    return () => document.body.classList.remove("no-scroll");
  }, [openDeleteCurrencyModal, openEditCurrencyModal]);

  //   Table edit & delete Data
  const handleEditModalOpen = (prop) => {
    setOpenEditCurrencyModal(true);
    const editCurrency = getCurrencyData?.find((item) => {
      return item.parameter === prop;
    });
    setEditCurrencyValues(editCurrency);
  };
  const handleDeleteModalOpen = (prop) => {
    setOpenDeleteCurrencyModal(true);
    const deleteCurrency = getCurrencyData?.find((item) => {
      return item.parameter === prop;
    });
    setDeleteCurrencyValues(deleteCurrency);
  };
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => (
              // eslint-disable-next-line
              clearFilters && handleReset(clearFilters),
              confirm({
                closeDropdown: false,
              })
            )}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <div className="search-btn">
        <SearchOutlined
          style={{
            color: filtered ? "#1677ff" : undefined,
          }}
        />
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
  const columns = [
    {
      title: "Currency Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Currency code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "C parameter",
      dataIndex: "cParameter",
      key: "cParameter",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Pricing",
      dataIndex: "pricing",
      key: "pricing",
    },
    ...(activeTab !== "standardPrice" && superAdmin
      ? [
          {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (text) => (
              <div className="actions">
                <TbEdit
                  className="action-svg"
                  onClick={() => handleEditModalOpen(text)}
                />
                {/* Uncomment if needed */}
                {/* <MdDeleteForever
                  className="action-svg red"
                  onClick={() => handleDeleteModalOpen(text)}
                /> */}
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let data;
        switch (activeTab) {
          case "stripeTrial":
            data = getTrailCurrencyData?.data;
            break;
          case "spreedlyTrial":
            data = getSpreedlyCurrencyData?.data;
            break;
          case "standardPrice":
            data = getPhoneTrackingCurrencyData?.data;
            break;
          default:
            data = [];
        }
        setGetCurrencyData(data?.currency_options);
        const formattedData = data?.currency_options?.map((item, index) => ({
          key: (index + 1).toString(),
          _id: item._id,
          name: item.name,
          code: item.code,
          cParameter: item.parameter,
          symbol: item.symbol,
          pricing: item.amount,
          action: item.parameter,
        }));
        setTableData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    activeTab,
    getPhoneTrackingCurrencyData,
    getSpreedlyCurrencyData?.data,
    getTrailCurrencyData?.data,
  ]);

  useEffect(() => {
    setEditDataCollation(editCurrencyValues);
  }, [editCurrencyValues, openEditCurrencyModal]);

  const handleChangeEditCurrency = (event) => {
    const { name, value } = event.target;
    setEditDataCollation((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleEdit = async () => {
    const errors = {};

    if (!editDataCollation.name) {
      errors.name = "Currency Name is required";
    }
    if (!editDataCollation.symbol) {
      errors.symbol = "Currency Symbol is required";
    }
    if (!editDataCollation.parameter) {
      errors.parameter = "C parameter is required";
    }
    if (!editDataCollation.amount) {
      errors.trial = "Pricing is required";
    }
    setEditModalError(errors);
    if (Object.keys(errors).length === 0) {
      const data = {
        name: editDataCollation.name,
        code: editDataCollation.code,
        parameter: editDataCollation.parameter,
        symbol: editDataCollation.symbol,
        amount: editDataCollation.amount,
        first_amount: editDataCollation?.trial?.first_amount,
        second_amount: editDataCollation?.trial?.second_amount,
      };
      const result =
        activeTab === "stripeTrial"
          ? await callUpsertStripe(data)
          : activeTab === "spreedlyTrial"
          ? await callUpsertSpreedly(data)
          : null;
      if (
        result.type ===
        `${
          activeTab === "stripeTrial"
            ? "upsertStripe"
            : activeTab === "spreedlyTrial"
            ? "upsertSpreedly"
            : ""
        }/fulfilled`
      ) {
        getTrailCurrency();
        getSpreedlyCurrency();
        Notify("success", result.payload.message, "");
        setOpenDeleteCurrencyModal(false);
        setOpenEditCurrencyModal(false);
      } else {
        Notify("error", result?.error?.message, "");
      }
    }
  };
  const handleCallDelete = async () => {
    const result = await deleteCurrency(deleteCurrencyValues.parameter);
    if (result.type === "deleteCurrency/fulfilled") {
      getCurrency();
      Notify("success", result.payload.message, "");
      setOpenDeleteCurrencyModal(false);
    } else {
      Notify("error", result.error.message, "");
    }
  };

  //   Close all modals
  const handleCloseModal = () => {
    setOpenDeleteCurrencyModal(false);
    setOpenEditCurrencyModal(false);
    setEditModalError({});
  };

  return (
    <div className="dashboard-main-section">
      <div className="container">
        <DashboardHeader />
        <div className="currency_data">
          <Tabs
            defaultActiveKey="stripeTrial"
            activeKey={activeTab}
            onChange={setActiveTab}
          >
            <Tabs.TabPane tab="Stripe Trial" key="stripeTrial">
              <Table
                columns={columns}
                dataSource={tableData}
                loading={loading}
                pagination={tableData?.length > 10}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Spreedly Trial" key="spreedlyTrial">
              <Table
                columns={columns}
                dataSource={tableData}
                loading={loading}
                pagination={tableData?.length > 10}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Standard Price" key="standardPrice">
              <Table
                columns={columns}
                dataSource={tableData}
                loading={loading}
                pagination={tableData?.length > 10}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>

      {/* Delete Currency Modal Start */}
      <Modal
        open={openDeleteCurrencyModal}
        onCancel={handleCloseModal}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="user-delete-confirm-model">
          <CiWarning size={90} color="#ff4d4f" />

          <div className="user-delete-text">
            Are you certain that you wish to delete this currency?
            {/* {t("ADMIN_DELETE_CONFIRM_TEXT")} */}
          </div>
          <div>
            <Button
              type="primary"
              danger
              className="user-delete-button"
              onClick={handleCloseModal}
            >
              {t("ADMIN_DELETE_CANCEL")}
            </Button>
            <Button
              type="primary"
              className="user-delete-yes-button"
              onClick={() => handleCallDelete()}
            >
              {t("ADMIN_DELETE_YES")}
            </Button>
          </div>
        </div>
      </Modal>
      {/* Delete Currency Modal Stop */}
      {/* Edit Currency Modal Start */}
      <Modal
        open={openEditCurrencyModal}
        onCancel={handleCloseModal}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        className={renderRTLClass()}
      >
        <div className="login-modal-section">
          <div className="login-modal-title padd">
            <p>Edit Currency</p>
          </div>
          <div className="form_modal_add_currency">
            <div className="input">
              <label>Currency Name</label>
              <Input
                name="name"
                defaultValue={editDataCollation.name}
                value={editDataCollation.name}
                onChange={handleChangeEditCurrency}
              />
              {validateErrors.name && <p>{validateErrors.name}</p>}
            </div>
            <div className="add-user-df">
              <div className="input">
                <label>Currency Symbol</label>
                <Select
                  defaultValue={editDataCollation.symbol}
                  value={editDataCollation.symbol}
                  disabled
                ></Select>
                {validateErrors.symbol && <p>{validateErrors.symbol}</p>}
              </div>
              <div className="input">
                <label>Currency code</label>
                <Input
                  name="code"
                  defaultValue={editDataCollation.code}
                  value={editDataCollation.code}
                  disabled
                />
              </div>
              <div className="input">
                <label>C parameter</label>
                <Input
                  name="parameter"
                  defaultValue={editDataCollation.parameter}
                  value={editDataCollation.parameter}
                  disabled
                />
                {validateErrors.parameter && <p>{validateErrors.parameter}</p>}
              </div>
            </div>
            <div className="add-user-df">
              <div className="input">
                <label>Trial Pricing</label>
                <Input
                  name="amount"
                  type="number"
                  defaultValue={editDataCollation.amount}
                  value={editDataCollation.amount}
                  onChange={handleChangeEditCurrency}
                //   value={userData.first_name}
                />
                {validateErrors.trial && <p>{validateErrors.trial}</p>}
              </div>
            </div>
            <div className="submit-btn">
              <button onClick={handleEdit}>Update</button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Edit Currency Modal Stop */}
    </div>
  );
};

export default CurrencyComponent;
