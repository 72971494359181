/* global gtag */
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Notify from "../../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { logAPI } from "../../../utils/commonUtils";
import axios from "axios";
import axiosRetry from "axios-retry";
import { useDispatch, useSelector } from "react-redux";
import Payment, { InitConfig } from "@solidgate/react-sdk";
import { solidGateSubscriptionCreate } from "../../../redux/slice/solidGate/solidGateSubscriptionCreateSlice";
import { isMobile } from "react-device-detect";
import { checkApplePayAvailability, checkGooglePayAvailability } from "../helpers";
import { solidGateIntentCreate } from "../../../redux/slice/solidGate/solidGateIntentCreateSlice";


axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: (retryCount) => {
    return retryCount * 1000; // Retry after 1 second on first attempt, 2 seconds on second attempt, etc.
  },
  retryCondition: (error) => {
    return true;
  }
});

const CheckoutComponent = (props) => {
  const {
    isLoading,
    setIsLoading,
    data,
    TrialCurr,
    subscriptionProductPrice,
    subscription,
    page,
    callgclidData,
    gclid,
    callSettingBanIp,
    ipData,
    searchNumber,
    email,
    setStep,
    merchantData,
    solidGateProductId,
    intentCreateData,
    setMerchantData,
    handleCloseModal
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const disPatch = useDispatch();
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cookieCountry = cookie.get("country_code");
  const params = cookie.get("param");
  const subscriptionPlan = window.location.pathname.includes("subscription");
  const pageVersion = page === "track" || page === "trace" ? "b" : "w";
  const userCountry = useSelector((state) => state.sendDetailsData?.getSendDetailsData?.data?.country) || cookieCountry;
  const userId = useSelector((state) => state.solidGateRegister?.solidGateRegister?.data?.user_id) || "";
  const [ip, setIp] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const [form, setForm] = useState(null);
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);

  ////////////////////////////////////////////////////////////////

  useEffect(() => {
    const checkAvailability = async () => {
      const isGooglePayAvailable = await checkGooglePayAvailability();
      const isApplePayAvailable = await checkApplePayAvailability();
      setIsGooglePayAvailable(isGooglePayAvailable);
      setIsApplePayAvailable(isApplePayAvailable);
    };
    checkAvailability();
  }, []);

  const handleOnReadyPaymentInstance = useCallback((form) => {
    setForm(form);
  }, []);

  const handleMounted = useCallback(() => {
    setIsMounted(true);
  }, []);

  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);

  const handleOnError = (e) => {
    console.log("handleOnError", e);
  };
  const handleOnFail = async (e) => {
    console.log("handleOnFail", e);
    setIsLoading(true)
    const intentResponse = await disPatch(
      solidGateIntentCreate(intentCreateData)
    );
    if (intentResponse.type === "solidGateIntentCreate/fulfilled") {
      const { paymentIntent, merchant, signature } =
        intentResponse.payload.data || {};
      setMerchantData({
        merchant: merchant || "",
        paymentIntent: paymentIntent || "",
        signature: signature || ""
      });
    }
    setErrorMessage(e.message);
    setIsLoading(false)
  };

  const handleOrderStatus = (e) => {
    if (e.response.order.status === "approved") {
      console.log('orderStatus', e)
      const { order, transactions } = e.response;
      const firstTransaction = Object.values(transactions)?.[0] || {};
      const { id: transactionId, card } = firstTransaction || {};
      gtag("event", "purchase", {
        transaction_id: transactionId,
        user_id: userId,
        page_version: pageVersion,
        purchase_amount: window.location.pathname.includes("subscription")
          ? subscriptionProductPrice?.amount
          : TrialCurr?.amount,
        currency: order?.currency?.toUpperCase(),
        email: data.email?.toLowerCase(),
        payment_method: card?.card_id,
        payment_details: [firstTransaction],
        postal_code: "", // pending
        country: userCountry || "",
      });
      if (!subscriptionPlan) {
        if (window.zaraz) {
          window.zaraz.track("purchase", {
            email: data.email?.toLowerCase(),
            user_id: userId,
            value: TrialCurr?.amount,
            currency_code: order?.currency?.toUpperCase(),
            transaction_id: transactionId,
            postal_code: "", // pending
            country: userCountry || "",
          });
        }
      }
      gtag("event", "conversion", {
        send_to: "AW-11387801114/zsDDCPmr7O8YEJqckLYq",
        value: subscriptionProductPrice.amount,
        currency: order?.currency?.toUpperCase(),
        transaction_id: transactionId,
        email: data.email?.toLowerCase(),
        postal_code: "", // pending
        country: userCountry || ""
      });
    } else {
      console.log("handleOrderStatus", e)
    }
  };

  const handleOnSuccess = async (e) => {
    try {
      setIsLoading(true);
      const apiPayload = {
        ip_address: ip,
        country: userCountry,
        price: solidGateProductId,
        order_id: e.order.order_id,
        email: e.order.customer_email,
        user_platform: isMobile ? "MOB" : "WEB",
        subscription_amount: subscriptionProductPrice?.amount,
        second_trial_sent_amount: Number(TrialCurr?.trial?.second_sent_amount),
      };
      logAPI("fe/stripe/confirm", "confirm api complete from FE", { ...apiPayload, ip: ip }, 200);

      const subscriptionResult = await disPatch(solidGateSubscriptionCreate(apiPayload));

      if (subscriptionResult.type === "solidGateSubscriptionCreate/fulfilled") {
        Notify("success", t("NOTIFY_STANDARD_SUBSCRIPTION"), "");
        const { token, role, email } = subscriptionResult.payload.data || {};
        if (
          window.location.pathname.includes("track") ||
          window.location.pathname.includes("trace")
        ) {
          if (
            params &&
            typeof params === "object" &&
            !Object.entries(params)
              .map(([key, value]) => {
                if (key === "off") {
                  return "off";
                }
              })
              .includes("off")
          ) {
            cookie.set("banned", "true");
            const data = {
              ip_address: ip,
              status: false
            };
            await callSettingBanIp(data);
          }
        }
        if (gclid && gclid.length > 0 && gclid[0].key && gclid[0].value) {
          const gclidData = {
            email: data.email?.toLowerCase(),
            key: gclid[0].key,
            value: gclid[0].value,
            signup: "on",
            ip_address: ip
          };
          callgclidData(gclidData);
        }
        cookie.set("token", token);
        cookie.set("role", role);
        cookie.set("email", email);
        if (searchNumber.D && searchNumber.n) {
          navigate(
            `/${lang}/dash?D=+${searchNumber?.D?.replaceAll(" ", "")}&n=${searchNumber.n
            }`
          );
        } else {
          navigate(`/${lang}/dash`);
        }
      } else {
        Notify("error", subscriptionResult.error.message, "");
        handleCloseModal()
      }
    } catch (error) {
      let errorMessage = `${JSON.stringify(error.message)} - ${JSON.stringify(
        error.stack
      )}`;
      logAPI("checkout-error", errorMessage, null, 400);
    } finally {
      setIsLoading(false)
    }
  };

  const handleOnInteraction = (e) => {
    console.log("handleOnInteraction", e);
  };
  const handleOnVerify = (e) => {
    console.log("handleOnVerify", e);
  };
  const handleOnRedirectMessage = (e) => {
    console.log("handleOnRedirectMessage", e);
  };
  const handleOnCustomStylesAppended = (e) => {
    console.log("handleOnCustomStylesAppended", e);
  };
  const handleCard = (e) => {
    console.log("card", e);
  };
  return (
    <div className="body-modal">
      <div className="payment-details payment_modal_area">
        <div className="df-details">
          <div className="left-payment">
            <p>{t("SIGNUP_PAYMENT_TITLE")}</p>
          </div>
          <div className="right-payment pay">
            <p>
              {subscription !== "standard"
                ? TrialCurr.symbol + TrialCurr.amount
                : TrialCurr.symbol + subscriptionProductPrice.amount}
            </p>
          </div>
        </div>
        <div className="right-payment pay">
          {!(page === "track" || page === "trace") && (
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                  amount:
                    subscriptionProductPrice.symbol +
                    subscriptionProductPrice.amount
                })}
            </span>
          )}
        </div>
        {(page === "track" || page === "trace") && (
          <div className="time-span">
            <span>
              {page === "track" || page === "trace"
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                  amount:
                    subscriptionProductPrice.symbol +
                    subscriptionProductPrice.amount
                })}
            </span>
          </div>
        )}
      </div>
      {/* <div ref={googleContainerRef} /> */}
      <Payment
        googlePayButtonParams={{
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          enabled: isGooglePayAvailable,
          containerId: "googlePay-payment-container",
          color: "black",
          type: "pay",
        }}
        applePayButtonParams={{
          enabled: isApplePayAvailable,
          integrationType: 'js',
          containerId: "applePay-payment-container",
          color: "black",
          type: "check-out",
        }}
        paypalButtonParams={{}}
        googlePayContainerRef={googleContainerRef}
        applePayContainerRef={appleContainerRef}
        merchantData={merchantData}
        formParams={{
          allowSubmit: false,
          // isCardHolderVisible: false,
          // googleFontLink: '//fonts.googleapis.com/css2?family=DM+Sans:ital@1&display=swap',
          // autoFocus: true
        }}
        onReadyPaymentInstance={handleOnReadyPaymentInstance}
        onMounted={handleMounted}
        width={"100%"}
        onInteraction={handleOnInteraction}
        onFail={handleOnFail}
        onOrderStatus={handleOrderStatus}
        onSuccess={handleOnSuccess}
        onSubmit={() => { setErrorMessage("") }}
        onError={handleOnError}
        onCard={handleCard}
        onVerify={handleOnVerify}
        onFormRedirect={handleOnRedirectMessage}
        onCustomStylesAppended={handleOnCustomStylesAppended}
        onPaymentDetails={(e) => console.log("onPaymentDetails", e)}
      />
      {errorMessage &&
        <div id="payment-message" style={{ color: "red" }}>
          {errorMessage}
        </div>
      }
      {isMounted && (
        <button
          disabled={isLoading}
          id="submit"
          className="hl_cta_wrap"
          type="submit"
          style={{ width: "100%", marginTop: 12 }}
          onClick={() => {
            form?.submit();
          }}
        >
          <span id="button-text">{t("SIGNUP_SUBMIT")}</span>
        </button>
      )}
      {!(page === "track" || page === "trace") && (
        <span id="sign-up-term">
          {t("CHECKOUT_NOTE", {
            trialPrice: TrialCurr?.symbol + TrialCurr?.amount,
            subPrice:
              subscriptionProductPrice?.symbol +
              subscriptionProductPrice?.amount
          })}
        </span>
      )}
    </div>
  );
};

export default CheckoutComponent;
