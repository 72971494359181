import React, { useEffect, useState } from "react";
import AmazonPayComponent from "./amazonPay";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { subscriptionCreateV7 } from "../../redux/slice/subscriptionCreateV7Slice";
import Notify from "../common/Notify/notify";
import { paymentIntentStatus } from "../../redux/slice/paymentIntentStatusSlice";

const AmazonPay = ({
  getsubscriptionCreateV7,
  productList,
  paymentIntentStatus,
  productListSpreedly
}) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const cur = cookies.get("currency");
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const trialProduct = productListSpreedly?.data.find(
    (item) => item.title === "trial"
  );
  const findCurrency = trialProduct?.currency_options?.find(
    (item => item.parameter === cur)
  )
  const [apiCalled, setApiCalled] = useState(true);
  const createV7Subscription = async () => {
    const paymentStatus = await paymentIntentStatus(
      searchNumber.payment_intent
    );
    if (
      paymentStatus.type === "paymentIntentStatus/fulfilled" &&
      paymentStatus.payload.data.status === "succeeded"
    ) {
      const data = {
        email: searchNumber.email,
        payment_intent_id: searchNumber.payment_intent,
        price: "price_1Pl7qCARglNY93qr4IAd0n08",
        // price: subscriptionProductPrice?.price_id,
        amount: searchNumber.amount,
        second_trial_amount: findCurrency?.trial?.second_sent_amount,
      };
      if (data.email && data.payment_intent_id && data.price && data.amount) {
        const result = await getsubscriptionCreateV7(data);
        if (result.type === "subscriptionCreateV7/fulfilled") {
          result.payload.message &&
            Notify("success", result.payload.message, "");
          cookies.set("token", result.payload.data.token);
          cookies.set("role", result.payload.data.role);
          cookies.set("email", result.payload.data.email);
          if (searchNumber.D && searchNumber.n) {
            navigate(
              `/${lang}/dash?D=+${searchNumber.D.replaceAll(" ", "")}&n=${
                searchNumber.n
              }`
            );
          } else {
            navigate(`/${lang}/dash`);
          }
        } else {
          navigate(
            `/${lang}/sign-up?D=${searchNumber.D}&n=${searchNumber.n}&email=${searchNumber.email}`
          );
        }
      }
    } else {
      navigate(
        `/${lang}/sign-up?D=${searchNumber.D}&n=${searchNumber.n}&email=${searchNumber.email}`
      );
    }
  };
  useEffect(() => {
    if (
      searchNumber.redirect_status &&
      searchNumber.redirect_status === "succeeded"
    ) {
      if (apiCalled) {
        setApiCalled(false);
        createV7Subscription();
      }
    } else {
      navigate(
        `/${lang}/sign-up?D=${searchNumber.D}&n=${searchNumber.n}&email=${searchNumber.email}`
      );
    }
  }, [searchNumber]);

  return <AmazonPayComponent />;
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductListSingle.productListSingleData,
    productListSpreedly: state.getProductList?.productListData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getsubscriptionCreateV7: (data) => dispatch(subscriptionCreateV7(data)),
    paymentIntentStatus: (data) => dispatch(paymentIntentStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AmazonPay);
