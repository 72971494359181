import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const getSmsConfig = createAsyncThunk("getSmsConfig", async () => {
  try {
    const response = await AxiosInstance.get(`/setting/get-sms-config`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const getPhoneTrackingCurrencySlice = createSlice({
  name: "getSmsConfig",
  initialState: {
    isLoading: false,
    getSmsConfigData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [getSmsConfig.pending]: (state) => {
      state.isLoading = true;
    },
    [getSmsConfig.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.getSmsConfigData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [getSmsConfig.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default getPhoneTrackingCurrencySlice.reducer;
