import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const upsertStripe = createAsyncThunk(
  "upsertStripe",
  async (data) => {
    try {
      const response = await AxiosInstance.put(`/pricing/update_stripe_trial_price`, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const upsertStripeSlice = createSlice({
  name: "upsertStripe",
  initialState: {
    isLoading: false,
    upsertStripeData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [upsertStripe.pending]: (state) => {
      state.isLoading = true;
    },
    [upsertStripe.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.upsertStripeData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [upsertStripe.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default upsertStripeSlice.reducer;
